<template>
  <section
    @click.self="$emit('closeModal')"
    @keyup.esc="$emit('closeModal')"
    tabindex="-1"
    class="modal__area--fixed"
  >
    <div
      class="modal__area mt-[2vh] 2xl:max-w-xl w-full animate__animated animate__fadeInDown animate__faster"
    >
      <h1 class="header__title">{{ $t("edit_plan") }}</h1>
      <form
        id="newPlan"
        class="flex flex-wrap 2xl:grid 2xl:grid-cols-2 gap-8 my-6"
        @submit.prevent="editPlan"
        :validation-schema="planSchema"
      >
        <div class="w-full 2xl:col-span-1 relative">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("name") }}
          </span>
          <input
            type="text"
            class="peer border-2 py-1.5 rounded-lg text-zinc-500 dark:text-zinc-300 outline-none w-full bg-zinc-700/10 dark:bg-zinc-700/30 px-3 focus:bg-zinc-700/20 dark:focus:bg-zinc-700/70 focus:border-zinc-200 font-mono placeholder:text-zinc-500 placeholder:text-sm placeholder:font-medium"
            :class="[
              nameError
                ? 'border-red-500 focus:border-red-500'
                : 'border-emerald-500 focus:border-emerald-500',
            ]"
            :placeholder="$t('insert_a_name')"
            ref="planName"
            v-model="name"
          />
          <span class="text-xs text-red-400 font-bold absolute top-0 right-0">
            {{ nameError }}
          </span>
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("what_is_the_limit_of_sip_branchs") }}
          </span>
          <Select
            :current="currentBranch"
            :options="selectBranch"
            @current-value="selectBranchValue"
          />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("which_is_limit_of_record_sip") }}
          </span>
          <Select
            :current="currentRecord"
            :options="selectSipRecord"
            @current-value="selectSipRecordValue"
          />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("how_much_sip_trunks") }}
          </span>
          <Select
            :current="currentTrunk"
            :options="selectSipTrunks"
            @current-value="selectSipTrunksValue"
          />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("how_much_surveys") }}
          </span>
          <Select
            :current="currentSurvey"
            :options="selectSurvey"
            @current-value="selectSurveyValue"
          />
        </div>
        <div class="w-full 2xl:col-span-1">
          <span
            class="text-xs font-semibold text-zinc-500 uppercase mb-2 block"
          >
            {{ $t("how_much_allowed_uras") }}
          </span>
          <Select
            :current="currentUra"
            :options="selectUra"
            @current-value="selectUraValue"
          />
        </div>
      </form>
      <div class="flex gap-5 justify-end items-center">
        <CloseModal :closeText="$t('close')" @click="$emit('closeModal')" />
        <SavePlan form="newPlan" type="submit" :saveText="$t('save')" />
      </div>
    </div>
  </section>
</template>

<script>
import SavePlan from "../../../../components/buttons/TheSaver.vue";
import CloseModal from "../../../../components/buttons/TheCloser.vue";
import Select from "../select/Index.vue";
import { ref, reactive } from "@vue/reactivity";
import { usePlanStore } from "../../../../../store/PlanStore.js";
import { useForm, useField } from "vee-validate";
import { onMounted } from "@vue/runtime-core";
import i18n from "../../../../../i18n";

export default {
  components: {
    SavePlan,
    CloseModal,
    Select,
  },
  props: ["plan"],
  emit: ["closeModal", "refreshPlans", "handleNotification"],
  setup(props, { emit }) {
    const PlanStore = usePlanStore();
    const planName = ref(null);

    const selectBranch = ref([]);
    const selectSipRecord = ref([]);
    const selectSipTrunks = ref([]);
    const selectSurvey = ref([]);
    const selectUra = ref([]);

    const currentSurvey = ref(props.plan.limit_pesquisa_satisfacao);
    const currentRecord = ref(props.plan.limit_ramais_gravacao);
    const currentBranch = ref(props.plan.limit_ramais_sip);
    const currentTrunk = ref(props.plan.limit_troncos_sip);
    const currentUra = ref(props.plan.limit_ura);

    function handleSelectOptions(arrTarget, maxValue, name = [], useSpecificValues = false) {
      arrTarget.push({ id: 101, value: null, plural: "" });

      if (useSpecificValues) {
        const specificValues = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
          15, 20, 25, 30, 35, 40, 45, 50,
          55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
          110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
          210, 220, 230, 240, 250, 300, 350, 400, 450, 500,
          550, 600, 650, 700, 750, 800, 900, 1000, 1100, 1200,
          1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000
        ];

        specificValues.forEach(value => {
          arrTarget.push({
            id: value,
            value: value,
            plural: `${value < 2 ? name[0] ?? "" : name[1] ?? ""}`
          });
        });
      } else {
          for (let j = 1; j <= maxValue; j++) {
            arrTarget.push({
              id: j,
              value: j,
              plural: `${j < 2 ? name[0] ?? "" : name[1] ?? ""}`,
            });
          }
        }
    }

    const selectBranchValue = (currentValue) => {
      newPlan.limit_ramais_sip = currentValue.value;
    };

    const selectSipRecordValue = (currentValue) => {
      newPlan.limit_ramais_gravacao = currentValue.value;
    };

    const selectSipTrunksValue = (currentValue) => {
      newPlan.limit_troncos_sip = currentValue.value;
    };
    const selectSurveyValue = (currentValue) => {
      newPlan.limit_pesquisa_satisfacao = currentValue.value;
    };
    const selectUraValue = (currentValue) => {
      newPlan.limit_ura = currentValue.value;
    };

    handleSelectOptions(selectBranch.value, 100, ["ramal", "ramais"], true);
    handleSelectOptions(selectSipRecord.value, 10, ["ramal", "ramais"]);
    handleSelectOptions(selectSipTrunks.value, 10, [
      "tronco SIP",
      "troncos SIP",
    ]);
    handleSelectOptions(selectSurvey.value, 10, ["pesquisa", "pesquisas"]);
    handleSelectOptions(selectUra.value, 10);

    const newPlan = reactive({
      nome: "",
      limit_ramais_sip: "",
      limit_ramais_gravacao: "",
      limit_troncos_sip: "",
      limit_pesquisa_satisfacao: "",
      limit_ura: "",
    });

    const planSchema = {
      name(value) {
        if (!value) {
          return "campo obrigatório";
        }

        if (value.length < 3) {
          return "Mínimo de 3 caracteres";
        }

        return true;
      },
    };

    const { handleSubmit } = useForm({
      validationSchema: planSchema,
    });

    const { value: name, errorMessage: nameError } = useField("name");

    function onInvalidSubmit() {
      const notificationInfos = {
        msg: i18n.global.t('check_fields_and_try_again'),
        status: "error",
        show: true,
      };

      emit("handleNotification", notificationInfos);
    }

    const editPlan = handleSubmit((values) => {
      newPlan.nome = values.name;
      newPlan.id = props.plan.id;

      PlanStore.editPlan(newPlan).then(({ data }) => {
        const notificationInfos = {
          msg: data.message,
          status: "success",
          show: true,
        };

        emit("handleNotification", notificationInfos);
        emit("refreshPlans");
        emit("closeModal");
      });
    }, onInvalidSubmit);

    onMounted(() => {
      name.value = props.plan.nome;
      planName.value.focus();
    });
    return {
      selectBranch,
      selectSipRecord,
      selectSipTrunks,
      selectSurvey,
      selectUra,
      selectBranchValue,
      selectSipRecordValue,
      selectSipTrunksValue,
      selectSurveyValue,
      selectUraValue,
      newPlan,
      editPlan,
      name,
      nameError,
      planSchema,
      currentSurvey,
      currentRecord,
      currentBranch,
      currentTrunk,
      currentUra,
      planName,
    };
  },
};
</script>
